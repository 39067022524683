import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BaseModal } from '@/shared/ui/modal';
import { formatDateLocalized } from '@/shared/utils/formatDateLocalized';
import releaseNotes from '@/shared/constants/release-notes.json';

import styles from './VersionModal.module.css';

const VersionModal = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(['devices', 'general', 'toasts']);
  const currentLanguage = i18n.language;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const previousAppVersion = localStorage.getItem('appVersion');

  const lastRelease = releaseNotes[releaseNotes.length - 1];

  useEffect(() => {
    if (lastRelease && lastRelease.version && lastRelease.version !== previousAppVersion) {
      setIsModalOpen(true);
      localStorage.setItem('appVersion', lastRelease.version);
    }
  }, [previousAppVersion, lastRelease]);

  const processModalClose = () => {
    setIsModalOpen(false);
  };

  const onSubmit = () => {
    navigate('/release_notes');
  };

  return (
    lastRelease && <BaseModal
      titleText={t('general:release_notes')}
      confirmText={t('general:learn_more')}
      visible={isModalOpen}
      onClose={processModalClose}
      onSubmit={onSubmit}
      showCancel={false}
    >
      <div className={styles.date}>
        {lastRelease?.date
          ? formatDateLocalized(new Date(lastRelease.date), 'LLL dd, yyyy', currentLanguage)
          : ''}
      </div>
      <h4>{`${t('general:new_version')} v.${lastRelease?.version}`}</h4>
      <ul className={styles.list}>
        {lastRelease.notes[currentLanguage].map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </BaseModal>
  );
};

export default VersionModal;
